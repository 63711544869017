/* eslint-disable react-hooks/exhaustive-deps */

import { API, Storage } from 'aws-amplify';
import { phone } from 'phone';
import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { toast } from 'react-hot-toast';
import PhoneInput from 'react-phone-input-2';
import { useDispatch } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { GoogleAddressField } from '../../components/Address';
import FormLabel from '../../components/FormLabel';
import PageHeader from '../../components/PageHeader';
import Spinner from '../../components/Spinner';
import { storeCarrier } from '../../stores/slice';
import uniqid from 'uniqid';
import { StorageManager } from '@aws-amplify/ui-react-storage';
import ReactGA from "react-ga4"
import { getLocationTimezone, handleApiError } from '../../helpers';
import { geocodeByPlaceId, getLatLng } from 'react-places-autocomplete';

const getCarrier = /* GraphQL */ `
  query GetCarrier($id: ID!) {
    getCarrier(id: $id) {
      id
      name
      alias
      phone
      email
      image
      active
      timezone {
        alias
        id
        name
      }
      address {
        name
        address1
        address2
        city
        state
        postalCode
      }
    }
  }
`;

const createCarrier = /* GraphQL */ `
  mutation CreateCarrier( $input: CreateCarrierInput!) {
    createCarrier(input: $input) {
      id
      name
    }
  }
`;

const updateCarrier = /* GraphQL */ `
  mutation UpdateCarrier( $input: UpdateCarrierInput!  ) {
    updateCarrier(input: $input) {
      id
      name
    }
  }
`;

const CarrierEditor = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate()
  const { id } = useParams()

  const [spinner, showSpinner] = useState(false);
  const [errors, setErrors] = useState();
  const [carrier, setCarrier] = useState({
    id: uniqid(),
    name: '',
    alias: '',
    email: '',
    phone: '',
    image: '',
    active: true,
    address: {
      address1: '',
      address2: '',
      city: '',
      state: '',
      postalCode: '',
      location: {
        latitude: 0,
        longitude: 0
      }
    },
  })

  useEffect(() => {
    id && getCarrierById()
    ReactGA.send({
      hitType: "pageview",
      page: id ? `/courier/${id}/edit` : "/courier/add",
    })
  }, [])

  const getCarrierById = () => {
    showSpinner(true)
    API.graphql({ query: getCarrier, variables: { id } })
      .then((response) => { setCarrier(response.data.getCarrier); })
      .catch((error) => handleApiError(error))
      .finally(() => showSpinner(false));
  }

  const isFormValid = () => {
    const error = {};

    if (!carrier?.name?.trim()) error['name'] = 'Name is required'
    if (!carrier?.email?.trim()) error['email'] = 'Email is required'
    if (!phone(carrier?.phone?.trim()).isValid) error['phone'] = 'Please enter a valid phone number';
    if (!carrier?.address?.address1?.trim()) error['address1'] = 'Address Line 1 is required';
    if (!carrier?.address?.postalCode?.trim() && !error['address1']) error['address1'] = 'please enter the address with the postal code';

    setErrors(error);

    return Object.keys(error).length === 0;
  };

  const handleChange = async (e) => setCarrier((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
  const handleAddress1Change = (address1) => setCarrier((prevState) => ({ ...prevState, address: { ...prevState.address, address1: address1 } }));
  const handleAddress2Change = (e) => setCarrier((prevState) => ({ ...prevState, address: { ...prevState.address, address2: e.target.value } }));
  const handlePhoneChange = (phone) => setCarrier((prevState) => ({ ...prevState, phone: `+ ${phone}` }));

  const handleAddressSelect = async (values, placeId) => {
    const places = await geocodeByPlaceId(placeId);
    const latLong = await getLatLng(places[0]);
    let street_number = '';
    let sub_locality1 = '';
    let sub_locality2 = '';
    let sub_locality3 = '';
    let route = '';
    let city = '';
    let state = '';
    let postalCode = '';
    let address2 = ''

    let location = {};
    if (latLong) {
      location.latitude = latLong.lat;
      location.longitude = latLong.lng;
    }

    places && places.length > 0 && places[0].address_components.forEach((address) => {
      if (address.types.includes('street_number')) street_number = address.short_name;
      if (address.types.includes('sublocality_level_3')) sub_locality1 = address.short_name;
      if (address.types.includes('sublocality_level_2')) sub_locality2 = address.short_name;
      if (address.types.includes('sublocality_level_1')) sub_locality3 = address.short_name;
      if (address.types.includes('route')) route = address.short_name;
      if (address.types.includes('locality')) city = address.short_name;
      if (address.types.includes('administrative_area_level_1')) state = address.short_name;
      if (address.types.includes('postal_code')) postalCode = address.long_name;
      address2 = ''
    });

    const address = {
      address1: street_number ? `${street_number} ${route}` : `${sub_locality1} ${sub_locality2} ${sub_locality3} ${route}`,
      address2,
      postalCode,
      city,
      state,
      location,
    }

    let timezone = await getLocationTimezone(location);
    setCarrier({
      ...carrier,
      address,
      timezone: timezone,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isFormValid()) {
      showSpinner(true);
      !carrier.image && delete carrier.image
      if (!id) carrier.active = true;
      const query = id ? updateCarrier : createCarrier;
      API.graphql({ query: query, variables: { input: carrier } })
        .then((response) => {
          if (id) {
            toast.success(`${carrier?.name} has been updated.`)
            dispatch(storeCarrier(response?.data?.updateCarrier));
            navigate(`/courier/${id}/overview`);
          } else {
            toast.success(`${carrier?.name} has been added.`)
            dispatch(storeCarrier(response?.data?.createCarrier));
            navigate(`/courier/${response?.data?.createCarrier?.id}/overview`);
          }
        })
        .catch((error) => handleApiError(error))
        .finally(() => showSpinner(false))
    }
  };

  const processFile = ({ file }) => {
    const ext = file.name.split('.').pop();
    return { file, key: `${uniqid()}.${ext}` };
  };

  const onUploadSuccess = ({ key }) => {
    Storage.get(key).then(url => {
      setCarrier(prevAttachment => ({ ...prevAttachment, image: url.split('?')[0] }))
    });
  };



  return (
    <>
      <PageHeader name={carrier.name ? carrier?.name : 'New Courier'} pageTitle={id ? "Edit Courier" : "Add New Courier"} classname={'container'} />

      <Spinner display={spinner}>
        <Container>
          <Form onSubmit={handleSubmit}>
            <Form.Group>
              <FormLabel required={true}>Name</FormLabel>
              <Form.Control type='text' name='name' placeholder='e.g. ABC Specialty Pharmacy' value={carrier?.name} onChange={handleChange} isInvalid={!!errors?.name} />
              <Form.Control.Feedback type='invalid'>{errors?.name}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className='mt-4'>
              <FormLabel>Alias</FormLabel>
              <Form.Control type='text' name='alias' placeholder='e.g. abc-specialty' value={carrier.alias} onChange={handleChange} />
            </Form.Group>
            <Row>
              <Col lg={6}>
                <Form.Group className='mt-4'>
                  <FormLabel required={true}>Email </FormLabel>
                  <Form.Control type='email' name='email' placeholder='e.g. john@pharmacy.com' value={carrier.email} onChange={handleChange} isInvalid={!!errors?.email} />
                  <Form.Control.Feedback type='invalid'>{errors?.email}</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className='mt-4'>
                  <FormLabel required={true}>Phone </FormLabel>
                  <Form.Control type='phone' name='phone' className='d-none' placeholder='e.g. 998-776-5543' value={carrier.phone || ''} onChange={handleChange} isInvalid={!!errors?.phone} />
                  <PhoneInput country='us' onlyCountries={['us']} value={carrier.phone} placeholder='e.g. 998-776-5543' onChange={handlePhoneChange} isInvalid={!!errors?.phone} />
                  <Form.Control.Feedback type='invalid'>{errors?.phone}</Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row className='mt-4'>
              <Col lg={6}>
                <Form.Group>
                  <FormLabel required={true}>Address Line 1 </FormLabel>
                  <GoogleAddressField name='address.address1' value={carrier.address?.address1}
                    error={errors?.address1} isInvalid={!!errors?.address1}
                    onChange={handleAddress1Change} onSelect={handleAddressSelect} />
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group>
                  <FormLabel >Address Line 2</FormLabel>
                  <Form.Control type='text' name='address2' placeholder='e.g. Suite 100' value={carrier?.address?.address2} onChange={handleAddress2Change} />
                </Form.Group>
              </Col>
            </Row>
            <Row className='mt-4'>
              <Col lg={4}>
                <Form.Group>
                  <FormLabel required={true}>City</FormLabel>
                  <Form.Control type='text' name='city' placeholder='e.g. Seattle' value={carrier?.address?.city} onChange={handleChange} disabled />
                </Form.Group>
              </Col>
              <Col lg={4}>
                <Form.Group>
                  <FormLabel required={true}>State</FormLabel>
                  <Form.Control type='text' name='state' placeholder='e.g. WA' value={carrier?.address?.state} onChange={handleChange} disabled />
                </Form.Group>
              </Col>
              <Col lg={4}>
                <Form.Group>
                  <FormLabel required={true}>Postal Code</FormLabel>
                  <Form.Control type='text' name='postalCode' placeholder='e.g. 98105' value={carrier?.address?.postalCode} onChange={handleChange} disabled />
                </Form.Group>
              </Col>
              <Col lg={12} className='mt-5'>
                <FormLabel>Upload Logo</FormLabel>
                <StorageManager
                  acceptedFileTypes={['.png', '.jpeg', '.jpg']}
                  accessLevel='public' maxFileCount={3}
                  showImages={false} path={`ShipperGroup / ${carrier?.id} / `}
                  processFile={processFile} onUploadSuccess={onUploadSuccess}
                />
              </Col>
            </Row>
            <hr />
            <Button type='submit' variant='dark' className='me-2' >Continue</Button >
            <Link to={id ? `/courier/${id}/overview` : `/courier`} className='btn btn-link text-muted'>Cancel</Link>
          </Form>
        </Container>
      </Spinner>
    </>
  )
}

export default CarrierEditor;
